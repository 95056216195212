<script setup>
import { useTimer } from '@/modules/base/hooks';
import { reactive, ref, computed, watch, useCssModule, onMounted } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@base/validators/composition';

const emit = defineEmits(['resend-email-request', 'update:startSendTimer']);
const props = defineProps({
  user: {
    type: Object,
    default: () => ({}),
  },
  startSendTimer: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  localStorageKey: {
    type: String,
    default: '',
  },
  debounceTimeInSeconds: {
    type: Number,
    default: 0,
  },
});

const style = useCssModule();
const isTimerActive = ref(false);
const {
  timer,
  reset: resetTimer,
  start: startTimer,
} = useTimer(props.debounceTimeInSeconds, () => {
  isTimerActive.value = false;
  localStorage.removeItem(props.localStorageKey);
  emit('update:startSendTimer', false);
});

const setResendEmailToLocalStorage = () => {
  const currentSeconds = new Date().setSeconds(
    new Date().getSeconds() + props.debounceTimeInSeconds
  );

  localStorage.setItem(props.localStorageKey, currentSeconds);
};

const startResendEmailTimer = (time) => {
  resetTimer(time);
  startTimer();
  isTimerActive.value = true;
};

const verifyEmailResendTimer = () => {
  if (!props.localStorageKey) return;
  const previousTimer = new Date(Number(localStorage.getItem(props.localStorageKey)));
  const remainingTimeInSeconds = Math.floor(
    (previousTimer.getTime() - new Date().getTime()) / 1000
  );
  if (remainingTimeInSeconds > 0) startResendEmailTimer(remainingTimeInSeconds);
};

onMounted(verifyEmailResendTimer);

const rules = { email: { email, required } };
const form = reactive({ email: props?.user?.email });
const v$ = useVuelidate(rules, form, { $autoDirty: true });

watch(
  () => props.startSendTimer,
  (startSendTimer) => {
    if (startSendTimer) {
      setResendEmailToLocalStorage();
      startResendEmailTimer(props.debounceTimeInSeconds);
    }
  }
);

const resendEmailAction = () => {
  emit('resend-email-request', form.email);
};

const emailErrorMessage = computed(() => v$?.value?.email?.$errors[0]?.$message);

const resendButtonClasses = computed(() => [
  style.resendBtn,
  !!emailErrorMessage.value && emailErrorMessage.value.length > 0 && style.error,
]);
</script>

<!-- TODO: refactor the layout with `XInputGroup` after Excalibur v0.39.0,
to align the field|button easier.
PR: https://github.com/clicksign/tavola/pull/8997
ISSUE: https://github.com/clicksign/excalibur/issues/445 -->

<template>
  <form
    :class="$style.form"
    @submit.prevent="resendEmailAction"
  >
    <i18n
      v-if="isTimerActive"
      data-testid="resendMessageTimer"
      path="account.emailValidation.timer.message"
      tag="p"
      :class="$style.resendWarn"
    >
      {{ timer }}
    </i18n>
    <template v-else>
      <XTextInput
        v-model="form.email"
        data-testid="emailInput"
        :disabled="isLoading"
        :label="$t('account.emailValidation.inputLabel')"
        :placeholder="$t('account.emailValidation.inputPlaceholder')"
        :validations="v$.email"
        :class="$style.emailInput"
        :message="emailErrorMessage"
      />
      <XButton
        size="sm"
        data-testid="resendBtn"
        :class="[resendButtonClasses]"
        :loading="isLoading"
        :disabled="v$.$invalid || isLoading"
        @click="resendEmailAction"
      >
        {{ $t('account.emailValidation.actions.resend') }}
      </XButton>
    </template>
  </form>
</template>

<style lang="scss" module>
.form {
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: var(--space-small-x);

  .emailInput {
    width: 100%;
  }

  .resendWarn {
    margin: var(--space-medium) auto;
    color: var(--color-neutral-800);
  }

  .resendBtn {
    width: 100%;
    max-width: 98px;

    &.error {
      align-self: center;
      margin-top: var(--space-small-xxx);
    }

    @include breakpoint('extra-small') {
      max-width: unset;
    }
  }

  @include breakpoint('extra-small') {
    flex-direction: column;
    gap: var(--space-regular);
  }
}
</style>
